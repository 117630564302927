html,
body,
#root {
  background-color: black;
  height: 100%;
}

.App {
  margin: 0 6em;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Body {
  flex-grow: 1;
}

.Header,
.Footer {
  flex-shrink: 0;
}

@media screen {
}
